.slot {
  display: flex;
  width: 8.4rem;
  height: 5.6rem;
  flex: 1 0 0;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border-radius: $border-radius-8;
  background: $primary-green;
  color: $secondary-beige;
  gap: 1.6rem;

  &.freezer {
    width: 6.55rem;
    height: 6.8rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    background: $primary-blue;
  }

  .slot-info {
    width: 6.2rem;

    &.freezer {
      width: 5.3rem;
    }

    .truncate-custom {
      overflow: hidden;
      width: 100%;
      max-height: 1.5rem * 2;
      text-overflow: ellipsis;
      white-space: pre-line;

      &.freezer {
        max-height: 1.4rem * 2;
      }
    }
  }

  &.low-stock,
  &.locked {
    border: 0 rem solid transparent;
    background: $secondary-beige;
    color: $secondary-red;
  }

  &.not-on-sale {
    border: 0.1rem solid $primary-green;
    background: transparent;
    color: $primary-green;

    &.freezer {
      border: 0.1rem solid $primary-blue;
      color: $primary-blue;
    }
  }

  .low-badge {
    position: absolute;
    z-index: 1;
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    justify-content: center;
    padding-top: 0.4rem;
    border: 0.2rem solid $secondary-beige;
    border-radius: 50%;
    background-color: $secondary-red;
    color: $secondary-beige;
    transform: translate(3rem, -2.8rem);
  }

  > p {
    line-height: 1.5rem;
  }
}

.slot-empty {
  display: flex;
  width: 8.4rem;
  height: 5.6rem;
  flex: 1 0 0;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  border-radius: $border-radius-8;
  gap: 1.6rem;

  &.freezer {
    width: 6.55rem;
    height: 7.6rem;
  }
}


