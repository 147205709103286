/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

 .visually-hidden{
  display: none;
 }

.carousel {
  position: relative;

  &.slide{
    margin-bottom: 4rem;

    .carousel-indicators{
      position: absolute;
      margin-top: 1.2rem;
      margin-bottom: unset;
      transform: translateY(4rem);

      button.active {
        background-color: $primary-blue;
        font-family: $poppins-font;
        font-size: $font-size-md;
        font-style: normal;
        font-weight: 600;
        opacity: unset;
      }

      button {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        background-color: #BDBDBD;
        font-family: $poppins-font;
        font-size: $font-size-md;
        font-style: normal;
        font-weight: 600;
        opacity: unset;
      }
    }

    .carousel-control-prev, .carousel-control-next {
      position: absolute;
      top: unset;
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: unset;
      transform: translateY(3.7rem);
    }

    .carousel-item{
      opacity: 0;
      transition: opacity 0.05s ease-out 0s;

      &.active{
        opacity: 1;
        transition: opacity 0.1s ease-in 0.3s;
      }
    }
  }
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  float: left;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: none;
  color: #fff;
  opacity: 0.5;
  text-align: center;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.carousel-indicators {
  position: absolute;
  z-index: auto;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  width: 3rem;
  height: 0.3rem;
  box-sizing: content-box;
  flex: 0 1 auto;
  padding: 0;
  border: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  background-clip: padding-box;
  background-color: #fff;
  cursor: pointer;
  opacity: 0.5;
  text-indent: -99.9rem;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

