$action-icons-border-radius: .3rem;
$tooltip-position: -4rem;

@include fade-from-above(fade-from-above, 2rem, $tooltip-position);

$possible-button-sizes: (
  xs: 2,
  sm: 3,
  md: 4,
  lg: 5,
  xl: 6,
);

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-5;
  transition: filter .3s ease-in-out;

  span {
    color: currentcolor;
  }

  @each $key, $size in $possible-button-sizes {
    &.size-#{$key} {
      width: #{$size}rem;
      height: #{$size}rem;

      > span {
        font-size: $font-size-md;
        transform: scale(calc($size * .4));
      }
    }
  }

  &.rounded {
    border-radius: .8rem;
  }

  &:hover {
    filter: brightness(.85);
  }

  &.primary {
    background-color: $primary-green;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary-green;
    }
  }

  &.secondary {
    background-color: $primary-blue;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary-blue;
    }
  }

  &.danger {
    background-color: $secondary-red;

    &:hover,
    &:active,
    &:focus {
      background-color: $secondary-red;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &:disabled {
    opacity: .3;
  }
}
