@include fade-from-side(error-from-side, -2rem, 0);

$select-item-height: 4.8rem;
$input-border: .2rem;

.dropdown-box {
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;

  > .input-text {
    margin-bottom: .8rem;

    > label {
      margin-bottom: 0.8rem;
      color: $primary-blue;
      font-family: $poppins-font;
      font-size: $font-size-md;
      font-weight: 500;
      line-height: 2.4rem;
    }

    > p {
      display: flex;
      overflow: hidden;
      margin-top: .8rem;
      -webkit-box-orient: vertical;
      color: $secondary-black;
      font-size: $font-size-md;
      font-weight: 300;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      line-height: 2.8rem;
      text-overflow: ellipsis;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    > .input-text {
      > label {
        color: #828282;
      }

      > p {
        display: flex;
        overflow: hidden;
        margin-top: .8rem;
        -webkit-box-orient: vertical;
        color: $secondary-black;
        font-size: $font-size-md;
        font-weight: 300;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        line-height: 2.8rem;
        text-overflow: ellipsis;
      }
    }

    .dropdown-selector {
      background-color: #f2f2f2;

      &:active {
        box-shadow: 0 0 0 $input-border $primary-blue;
        transition: box-shadow .2s ease-in-out;
      }

      > span {
        color: #bdbdbd;
        transition: transform .2s ease-in-out;
      }
    }
  }

  .dropdown-selector {
    display: flex;
    width: 100%;
    height: 4.8rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.6rem;
    border-radius: $border-radius-8;
    background-color: #f2f2f2;

    &:active {
      box-shadow: 0 0 0 $input-border $primary-blue;
      transition: box-shadow .2s ease-in-out;
    }

    > label {
      padding-top: .5rem;
      color: #828282;
      font-family: $poppins-font;
      font-size: $font-size-md;
      font-weight: 300;
      line-height: 2.8rem;

      &.selected {
        color: $secondary-black;
      }
    }

    > span {
      color: $primary-blue;
      transition: transform .2s ease-in-out;
    }
  }

  .dropdown-list {
    position: absolute;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 0;
    max-height: unset;
    flex-direction: column;
    align-items: flex-start;
    border-radius: $border-radius-8;
    background-color: $secondary-white;
    box-shadow: 0 0 .5rem rgb(187 187 187 / .5);
    transform: translateY(0);
    transition: transform .5s ease-in-out;

    > button {
      width: 100%;
      height: $select-item-height;
      padding: 1rem 1.6rem;
      color: $secondary-black;
      cursor: pointer;
      font-size: $font-size-md;
      font-weight: 300;
      line-height: 2.8rem;
      text-align: left;
      touch-action: pan-y;
      vertical-align: middle;

      &:hover {
        background-color: rgba($primary-blue, .1);
      }
    }
  }

  &.dropdown-open {
    .dropdown-selector {
      span {
        transform: rotate(180deg);
      }

      &:hover {
        box-shadow: 0 0 0 $input-border $primary-blue;
        transition: .2s all ease-in-out;
      }
    }

    .dropdown-list {
      z-index: 50;
      overflow: auto;
      height: unset;
      max-height: calc(4 * $select-item-height);
      transform: translateY(.3rem);
    }
  }

  &.error {
    > .dropdown-selector {
      box-shadow: 0 0 0 .1rem $secondary-red;

      &:hover {
        box-shadow: 0 0 0 $input-border $primary-blue;
      }

      &:active {
        box-shadow: 0 0 0 $input-border $secondary-red;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    label.error {
      left: 0;
      width: 100%;
      padding: 1rem 1rem 0;
      animation: error-from-side .5s ease-in-out forwards;
      color: $secondary-red;
      font-family: $poppins-font;
      font-size: $font-size-sm;
    }
  }

  .input-icon {
    position: absolute;
    right: 0;
    bottom: .4rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;
  }
}
