.settings {
  width: 49.5rem;
  align-items: center;

  .title {
    color: $primary-blue;
    font: 600 $font-size-xxl $poppins-font;
    text-align: center;
    text-transform: uppercase;
  }

  .settings-form {
    max-width: 18.6rem;
  }

  .submit-btn {
    width: 100%;
    margin-top: 2.4rem;
  }
}
