.deciding-view {
  width: 55.6rem;
}

.delete-view {
  width: 49.5rem;
  padding: 2.4rem;
}

.empty-view {
  width: 49.5rem;
}

.delete-form {
  display: flex;
  max-width: 19.5rem;
  flex-direction: column;
  align-self: center;
  gap: 2.4rem;
}

.delete-frame {
  .flavor-input {
    margin-bottom: 5.3rem;

    .margin {
      margin-top: 1.1rem;
    }
  }

  .last-weight {
    height: 10.5rem;
    margin-bottom: 2.1rem;

    .margin {
      margin-top: 1.1rem;
    }
  }

  .date-input {
    min-width: 17.9rem;
  }

  .weight-input {
    width: 100%;
  }

  .reason-input {
    min-height: 12rem;
    margin-bottom: 1.2rem;
  }

  .additional-info{
    min-height: 10.5rem;
    margin-top: 5.2rem;
    margin-bottom: 1.5rem;
  }
}
