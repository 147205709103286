.panel {
  &.panel-freezer{
    position: relative;
    min-width: 33.4rem;
    padding: 0.8rem;
    margin-top: 1.2rem;
    box-shadow: unset;
    gap: 1.2rem;
  }

  .freezer-header {
    position: relative;

    > .drawer-label {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 6.4rem;
      padding: 0 0.8rem;
      border: 0.1rem solid $primary-blue;
      border-radius: $border-radius-8;
      color: $primary-blue;
      line-height: 1.96rem;
    }
  }

  .freezer-grid {
    display: grid !important;
    column-gap: 0.8rem;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    grid-template-areas:
                      "slot1 slot1 slot2 slot2 slot3 slot3 slot4 slot4"
                       "empt slot5 slot5 slot6 slot6 slot7 slot7 emp";
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr 1fr;
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    row-gap: 1.6rem;

    .position {
      position: relative;
      display: flex;
      width: 7.35rem;
      height: 7.6rem;
      flex: 1 0 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-8;
      background: #BDBDBD;
      gap: 0.6rem;

      .position-number {
        color: $primary-blue;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }


  .freezer-grid .position:nth-child(1) {
    grid-area: slot1;
  }

  .freezer-grid .position:nth-child(2) {
    grid-area: slot2;
  }

  .freezer-grid .position:nth-child(3) {
    grid-area: slot3;
  }

  .freezer-grid .position:nth-child(4) {
    grid-area: slot4;
  }

  .freezer-grid .position:nth-child(5) {
    grid-area: slot5;
  }

  .freezer-grid .position:nth-child(6) {
    grid-area: slot6;
  }

  .freezer-grid .position:nth-child(7) {
    grid-area: slot7;
  }

  .slider-left {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0.5rem;
    height: 20rem;
    transform: translateY(-50%);
  }

  .slider-right {
    position: absolute;
    top:50%;
    right: 0;
    width: 0.5rem;
    height: 20rem;
    transform: translateY(-50%);
  }
}
