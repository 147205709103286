.button-default {
  display: flex;
  width: unset;
  height: 4.8rem;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-8;
  color: $secondary-white;
  cursor: pointer;
  font-family: $poppins-font;
  font-size: $font-size-md;
  font-weight: 600;
  line-height: $line-height-base;
  text-align: center;

  > span.icon {
    margin-right: 1.6rem;
    font-size: 2rem;
    line-height: inherit;
  }

  &:disabled {
    border: 0;
    background-color: #e0e0e0;
    color: #828282;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: #526d57;
      box-shadow: unset;
    }
  }

  &.button-padding {
    padding: 1.2rem 1.6rem;
  }
}

/*
--------------------------------------------------------------------------------
Colors
--------------------------------------------------------------------------------
*/

.primary-style {
  background-color: $primary-green;

  &:hover,
  &:focus {
    background-color: #526d57;
  }
}

.secondary-style {
  background-color: $primary-yellow;
  transition: background-color .3s ease-in-out;

  &:hover,
  &:focus {
    background-color: #ab925b;
  }
}

.tertiary-style {
  background-color: transparent;
  color: $primary-green;
  transition: background-color .3s ease-in-out;

  &:hover,
  &:focus {
    background-color: $primary-green;
    color: $secondary-white;
  }
}

.text-only-style {
  background-color: transparent;
  color: $primary-green;
  transition: background-color .3s ease-in-out;

  &:hover,
  &:focus {
    background-color: #eaf3ec;
  }
}

.img-button {
  align-items: center;
  background-color: transparent;
  transition: filter 0.3s, background-color 0.3s;

  &:hover {
    background-color: transparent;
  }
}

.add-button {
  background-color: $primary-blue;
  transition: background-color .3s;

  &:hover,
  &:focus {
    background-color: $hover-blue;
  }
}

.delete-button {
  align-items: center;
  background-color: $secondary-red;
  transition: filter 0.3s, background-color 0.3s;

  &:hover,
  &:focus {
    background-color: $hover-red;
  }

  &:disabled,
  &:disabled:hover {
    background-color: $secondary-red;
    color: $secondary-white;
    opacity: .5;
  }
}

.delete {
  border: 0.25rem solid $secondary-red;
  background-color: transparent;
  color: $secondary-red;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;

  &:hover,
  &:focus {
    border: 0.25rem solid $hover-red;
    background-color: transparent;
    color: $hover-red;
  }
}
