main.statistics-page {
  padding: 4rem 1rem 1rem;
  gap: 0.8rem;

  .stat-row {
    width: 100%;
    max-width: 102.4rem;
  }

  .stat-table-wrapper {
    padding: 1.6rem 0 2.4rem;
    border-radius: $border-radius-15;
    background-color: $secondary-beige;
  }

  .stat-header {
    display: flex;
    align-items: center;
    padding: 1.2rem 2.4rem;
    border-radius: $border-radius-15;
    background-color: $secondary-beige;
    gap: 2.4rem;

    .stat-controls {
      display: flex;
      align-items: flex-end;
      gap: 0.8rem;

      > .input-group > .input{
        min-width: 22.1rem;
        height: 4.8rem;
      }

      .download-button {
        min-width: 19rem;
        height: 4.8rem;
      }
    }

    .stat-divider {
      height: 100%;
      border: solid .05rem $quaternary-gray;
    }

    .stat-group {
      position: relative;
      min-width: 21rem;
      padding: 0.8rem;
      padding-left: 7.0rem;

      > .stat-img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      > .stat-title {
        color: $primary-blue;
        font: 600 1.6rem $poppins-font;
        line-height: 2.4rem;
        text-transform: uppercase;
      }

      > .stat-subtitle {
        margin-bottom: 0.8rem;
        color: $tertiary-gray;
        font: 400 $font-size-md $poppins-font;
        line-height: 1.8rem;
      }

      > .stat {
        font: 500 $font-size-xl $poppins-font;
        line-height: 2.4rem;
      }
    }
  }
}
