main.reset-password-page {
  .panel {
    form {
      align-self: center;
      gap: 4.8rem;

      .input-group {
        min-width: 37.5rem;
        margin: 0 3.2rem;
      }
    }

    button {
      min-width: 16rem;
    }
    
    p { 
      max-width: 50rem;
    }

    &.modal {
     p {
        max-width: unset;
      }
    }
  }

  a {
    color: $primary-green;
    line-height: $line-height-base;
    text-align: center;
  }
}
