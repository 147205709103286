@use 'sass:color';

.slot {
  display: flex;
  width: 8.4rem;
  height: 5.6rem;
  flex: 1 0 0;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  border-radius: $border-radius-8;
  background: $primary-green;
  color: $secondary-beige;
  gap: 1.6rem;

  &.low-stock {
    background: $secondary-beige;
    color: $secondary-red;
  }
}

.draggable {
  &.active > .slot {
    opacity: .4;
  }

  &.over {
    > .slot {
      background: color.scale($primary-green, $lightness: -16%);

      &.freezer {
        background: color.scale($primary-blue, $lightness: -16%);
      }
    }

    > .slot.locked,
    > .slot.low-stock {
      background: color.scale($secondary-beige, $lightness: -6%);
    }

    > .slot-empty,
    > .slot.not-on-sale {
      background: color.scale($quaternary-gray, $lightness: -10%);
    }
  }
}
