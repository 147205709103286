.stats-table {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
}

.overflow-wrapper {
  width: 100%;
  flex-shrink: 0;
  overflow-x: auto;

  &.stretch {
    min-height: 42.6rem;
  }
}

.table-empty {
  padding: 5.9rem 0;
  color: $tertiary-gray;
  font: 400 $font-size-md $poppins-font;
  text-align: center;
}

.table {
  width: 100%;
  font: 400 $font-size-md $poppins-font;
  white-space: nowrap;

  .tr:nth-child(even) {
    background-color: rgba($primary-green, .10);
  }

  .th {
    position: relative;
    min-width: 10.6rem;
    padding: .4rem 4rem .8rem .8rem;
    color: $primary-blue;
    font-weight: 600;
    text-transform: uppercase;
    white-space: pre-line;

    > .caret {
      position: absolute;
      top: 0;
      right: .8rem;
      margin-left: .8rem;
      color: $tertiary-gray;
    }

    &:first-child {
      width: 31.4rem;
      padding: .4rem .8rem .8rem 1.6rem;
      text-align: left;

      > .caret {
        right: initial;
      }
    }

    &:nth-child(5) {
      white-space: pre;
    }

    &.sort > .caret {
      color: $primary-yellow;
    }

    &.asc > .caret {
      transform: rotate(180deg);
    }
  }

  .td {
    padding: .8rem 3.4rem .8rem .8rem;
    line-height: $line-height-base;
    text-align: center;

    &:first-child {
      padding: .8rem .8rem .8rem 1.6rem;
      text-align: left;
      white-space: pre-line;
    }
  }
}
