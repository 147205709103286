@use "sass:color";

form {
  .form {
    margin-bottom: 3.2rem;
  }

  .input-text {
    label {
      line-height: 2.4rem;
    }
  }

  label.error {
    position: relative;
    left: 0;
    display: flex;
    width: 100%;
    padding: 0.8rem;
    animation: error-from-side .5s ease-in-out forwards;
    color: $secondary-red;
    font-family: $poppins-font;
    font-size: $font-size-md;
    font-size: $font-size-sm;
    font-weight: 400;
    text-overflow: ellipsis;
  }

  .form-section {
    height: 12.4rem;
  }

  .form-item {
    width: 20.7rem;
  }

  .form-actions {
    display: flex;
    column-gap: 2.4rem;

    > * {
      width: 50%;
    }

    > .lock-btn {
      background: $secondary-red;

      &:hover,
      &:focus {
        background: color.scale($secondary-red, $lightness: -20%);
      }
    }

    > .unlock-btn {
      padding: 1.2rem 1.3rem;
      background: $primary-blue;

      &:hover,
      &:focus {
        background: color.scale($primary-blue, $lightness: -20%);
      }
    }
  }
}

.modal {
  .nay-slots {
    width: 58rem;
  }

  .aye-slots {
    width: 49.5rem;
  }

  .no-slots > p {
    margin-bottom: 2.4rem;
  }

  .ok-button {
    width: 12.4rem;
  }
}
