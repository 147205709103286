/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

$include-column-box-sizing: true !default;

@import "functions";
@import "variables_bs";
@import "mixins/breakpoints";
@import "mixins/container";
@import "mixins/grid";
@import "mixins/utilities";
@import "vendor/rfs";
@import "containers";
@import "grid";
@import "utilities";

// Only use the utilities we need
$utilities: map-get-multiple(
  $utilities,
  (
    "display",
    "order",
    "flex",
    "flex-direction",
    "flex-grow",
    "flex-shrink",
    "flex-wrap",
    "justify-content",
    "align-items",
    "align-content",
    "align-self",
    "margin",
    "margin-x",
    "margin-y",
    "margin-top",
    "margin-end",
    "margin-bottom",
    "margin-start",
    "negative-margin",
    "negative-margin-x",
    "negative-margin-y",
    "negative-margin-top",
    "negative-margin-end",
    "negative-margin-bottom",
    "negative-margin-start",
    "padding",
    "padding-x",
    "padding-y",
    "padding-top",
    "padding-end",
    "padding-bottom",
    "padding-start",
    "position",
    "text-align",
    "text-transform",
    "top",
    "line-height",
    "gap",
    "width",
    "height",
    "max-width",
    "viewport-height",
    "viewport-width",
    "overflow",
    "letter-spacing"
  )
);

@import "utilities/api";
