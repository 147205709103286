main.edit-password-page {
  .panel {
    form {
      align-self: center;
      gap: 3.2rem;

      .input-group {
        min-width: 37.5rem;
      }
    }

    button {
      min-width: 16rem;
    }
    
    p { 
      max-width: 50rem;
    }
  }
}
