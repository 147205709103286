.panel {
  &.panel-counter{
    padding: 2.4rem 1.2rem;
    margin-top: unset;
    box-shadow: unset;
  }

  .counter-grid {
    display: grid;
    gap: 0.8rem;
    grid-template-columns: repeat(10, 1fr);
    row-gap: 2.4rem;

    .position {
      position: relative;
      display: flex;
      width: 9.2rem;
      height: 15.5rem;
      flex: 1 0 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.4rem;
      border-radius: $border-radius-8;
      background: #BDBDBD;
      gap: 0.6rem;

      .position-number {
        color: #536757;
        font-size: $font-size-md;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
    }

  }
}
