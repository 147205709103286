.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .8rem 1.6rem;
  border-radius: .8rem;
  margin-bottom: 2.4rem;
  font: 400 1.6rem $poppins-font;

  &.info {
    background-color: rgba($primary-blue, .1);
    color: $primary-blue;
  }

  &.danger {
    background-color: $secondary-red-10;
    color: $secondary-red;
  }
}
