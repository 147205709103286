$select-item-height: 4.8rem;

main.store-dashboard-page {
  padding-top: 4rem;

  .grid {
    grid-gap: 0.5rem;
  }

  .modal {
    .form {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
  }

  .freezers-row {
    max-width: 101.6rem;
  }

  /* stylelint-disable selector-class-pattern */

  .dropdown-__control {
    display: flex;
    width: 100%;
    height: 4.8rem;
    align-items: center;
    justify-content: space-between;
    padding: unset;
    border-style: unset;
    border-radius: $border-radius-8;
    background-color: $secondary-white;
    font-family: $poppins-font;
    font-size: $font-size-md;
    font-weight: 300;
    line-height: 2.8rem;

    &:active {
      box-shadow: 0 0 0 $input-border $primary-blue;
      transition: box-shadow .2s ease-in-out;
    }

    &:hover {
      box-shadow: 0 0 0 $input-border $secondary-white;
      transition: box-shadow .2s ease-in-out;
    }

    > label {
      padding-top: .5rem;
      color: $primary-blue;
      font-size: $font-size-md;

      &.selected {
        color: $primary-blue;
      }
    }

    > span {
      color: $primary-blue;
      transition: transform .2s ease-in-out;
    }

    .dropdown-__single-value {
      margin: unset;
    }
  }

  .dropdown-__value-container {
    padding: 1rem 1.6rem;
    font-family: $poppins-font;
  }

  .dropdown-indicatorContainer {
    padding-right: 1.6rem;
  }

  .dropdown-__indicator-separator{
    background-color: unset;
  }

  .dropdown-__menu {
    margin: unset;
    box-shadow: unset;

    .dropdown-__menu-list {
      position: absolute;
      z-index: 50;
      display: flex;
      overflow: auto;
      width: 100%;
      height: 0;
      height: auto;
      max-height: calc(4 * $select-item-height);
      flex-direction: column;
      align-items: flex-start;
      border-radius: $border-radius-5;
      background-color: $secondary-white;
      box-shadow: 0 0 .5rem rgb(187 187 187 / .5);
      font-family: $poppins-font;
      font-size: $font-size-md;
      font-weight: 300;
      line-height: 2.8rem;
      transform: translateY(0);
      transform: translateY(.3rem);
      transition: transform .5s ease-in-out;

      > button {
        width: 100%;
        height: $select-item-height;
        padding: 1rem 1.6rem;
        color: $primary-blue;
        cursor: pointer;
        font-size: $font-size-md;
        text-align: left;
        vertical-align: middle;

        &:hover {
          background-color: rgba($primary-blue, .1);
        }
      }

      .dropdown-__option{
        width: 100%;
        height: $select-item-height;
        padding: 1rem 1.6rem;
        color: $secondary-black;
        cursor: pointer;
        font-size: $font-size-md;
        font-weight: 300;
        line-height: 2.8rem;
        text-align: left;
        vertical-align: middle;
      }

      .dropdown-__option--is-selected, .dropdown-__option--is-focused {
        background-color: #f2f2f2;
        color: $secondary-black;
      }
    }
  }
  /* stylelint-enable selector-class-pattern */
}

