@use "sass:color";

.edit-carapine {
  min-width: 90.9rem;
  max-width: 90.9rem;
  gap: 2rem;

  &.unlock-carapine {
    min-width: 49.5rem;
    max-width: auto;
  }

  > .title {
    margin-bottom: 1.2rem;
    color: $primary-blue;
    font: 600 $font-size-xxl $poppins-font;
    text-align: center;
    text-transform: uppercase;
  }

  .edit-carapina-form-wrapper {
    display: grid;
    width: 100%;
    min-height: 42.3rem;
    align-content: start;
    padding-right: 3.2rem;
    border-right: .1rem solid $secondary-white;
    gap: 2.4rem;
    grid-template: auto / repeat(2, 1fr);
    grid-template-areas:
      "flavor flavor"
      "prodDate ."
      "weight initWeight"
      "onSale onSale";

    .flavor {
      grid-area: flavor;
    }

    .prod-date {
      grid-area: prodDate;
    }

    .weight {
      grid-area: weight;
    }

    .initial-weight {
      grid-area: initWeight;
    }

    .on-sale {
      margin-top: 2.4rem;
      grid-area: onSale;
    }

    .switch-group {
      display: flex;
      min-height: 6rem;
      align-items: center;
      margin-top: .8rem;
    }
  }

  .unlock-btn {
    width: 100%;
    padding: 1.2rem 1.3rem;
    background: $primary-blue;

    &:hover,
    &:focus {
      background: color.scale($primary-blue, $lightness: -20%);
    }
  }

  .stock-extra {
    display: flex;
    max-height: 30rem;
    flex-direction: column;
    gap: 0.4rem;
    overflow-y: auto;

    .stock-extra-item {
      padding: 0.8rem;
      border-radius: 0.8rem;
      background: rgba($primary-blue, 0.1);

      > p {
        line-height: 2rem;
      }
    }
  }

  .weight-history {
    padding-bottom: 1.7rem;

    > .add-btn {
      width: 100%;
      height: 4.8rem;
      margin-bottom: 1.2rem;
      color: $secondary-white;
    }

    .history-list {
      display: flex;
      max-height: 21.6rem;
      flex-direction: column;
      padding-right: 2.4rem;
      margin-top: 1.2rem;
      gap: .8rem;
      overflow-y: scroll;
    }

    .history-item {
      display: flex;
      gap: .8rem;

      > .date {
        flex-grow: 1;
        padding: 0;
        margin: 0;

        & .date {
          margin: 0;
        }
      }

      > .weight {
        width: 10.8rem
      }

      > .remove-btn {
        width: 4.8rem;
        height: auto;
        background-color: $secondary-red;
        color: $secondary-white;
      }
    }
  }
}
