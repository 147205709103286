.manage-flavors {
  position: relative;
  max-width: 72.9rem;
  max-height: 62rem;

  > .title {
    color: $primary-blue;
    font: 600 $font-size-xxl $poppins-font;
    text-align: center;
    text-transform: uppercase;

  }

  > .content {
    margin-bottom: 2rem;
  }

  .flavors-table-wrapper {
    margin-bottom: 2.4rem;
  }
}

.manage-flavors .flavors-table {
  width: 100%;
  font: 400 $font-size-md $poppins-font;
  table-layout: fixed;

  .tr {
    &:nth-child(even) {
      background-color: rgba($primary-green, .10);
    }

    &.error > .td {
      vertical-align: baseline;
    }
  }

  .th {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: .4rem 2.4rem 3rem;
    background-color: $secondary-beige;
    color: $primary-blue;
    font-size: $font-size-lg;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    > .caret {
      position: absolute;
      top: 0;
      right: .8rem;
      margin-left: .8rem;
      color: $tertiary-gray;
    }

    &:first-child {
      text-align: left;

      > .caret {
        right: initial;
      }
    }

    &:last-child {
      text-align: right;
    }

    &.sort > .caret {
      color: $primary-yellow;
    }

    &.asc > .caret {
      transform: rotate(180deg);
    }
  }

  .td {
    height: 7.2rem;
    padding: .8rem 2.4rem;
    hyphens: auto;
    overflow-wrap: break-word;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    .flavor-input.input-group {
      margin-left: -1.6rem;

      > .input {
        height: auto;
        margin-bottom: 0;
      }

      > .input > .flavor-input {
        height: 4.8rem;
      }
    }
  }

  .row-action {
    display: inline-flex;
    width: 4.4rem;
    height: 4.4rem;
    color: white;

    &.margin{
      margin-right: 0.8rem;
    }
  }

}
