.select-flavor {
  .option-action {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 1rem 1.6rem;
    border-top: .05rem solid $quaternary-gray;
    background-color: $secondary-white;
    color: inherit !important;
    font: 500 $font-size-md $poppins-font;
    line-height: 2.8rem;

    &:hover {
      background-color: #f2f2f2 !important;
    }
  }

  .label {
    color: $primary-blue;
    font: 500 $font-size-md $poppins-font;
  }

  .select-field {
    margin-top: 1.1rem;
  }
}
