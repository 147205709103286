@include fade-from-side(error-from-side, -2rem, 0);

$input-border: .2rem;

.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: height .2s ease-in-out;

  > .input-text {
    display: flex;
    margin-bottom: .8rem;

    > label {
      color: $primary-blue;
      font-family: $poppins-font;
      font-size: $font-size-md;
      font-weight: 500;
      line-height: 2.4rem;
    }

    > p {
      display: flex;
      overflow: hidden;
      -webkit-box-orient: vertical;
      color: #828282;
      font-family: $poppins-font;
      font-size: $font-size-md;
      font-weight: 400;
      -webkit-line-clamp: 2;
      line-height: 2.4rem;
      text-overflow: ellipsis;
    }
  }

  > .input {
    position: relative;
    height: 4.8rem;
    color: $secondary-black;
    transition: box-shadow margin-bottom height .2s ease-in-out;

    input[type="date"] {
      &::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 1.6rem;
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        border-width: thin;
        background-repeat: no-repeat;
        opacity: .5;
      }

      &:disabled::-webkit-calendar-picker-indicator {
        visibility: visible;
      }
    }

    input::-webkit-datetime-edit-fields-wrapper {
        position: relative;
        left: 3.6rem;
    }

    >span, >p{
      position: absolute;
      top:50%;
      right: 1.6rem;
      box-sizing:border-box;
      padding: 0.5rem;
      color: #828282;
      transform: translateY(-55%);
    }

    > input {
      width: 100%;
      height: 100%;
      padding: 1rem 1.6rem;
      border-radius: $border-radius-8;
      background-color: #f2f2f2;
      font-family: $poppins-font;
      font-size: $font-size-md;
      font-weight: 300;

      &::placeholder {
        color: #828282;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }

      &.input-side-icon {
        padding: 0 1.6rem 0 .8rem;
      }

      
    }

    &.error {
      &.error-message {
        margin-bottom: 2.5rem;
      }

      input {
        padding: 0 4rem 0 .8rem;
        box-shadow: inset 0 0 0 .1rem $secondary-red;

        &:hover {
          box-shadow: inset 0 0 0 $input-border #f2f2f2;
        }

        &:active {
          box-shadow: inset 0 0 0 $input-border $secondary-red;
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    label.error {
      position: relative;
      left: 0;
      display: flex;
      width: 100%;
      padding: 0.8rem;
      animation: error-from-side .5s ease-in-out forwards;
      color: $secondary-red;
      font-size: $font-size-sm;

      &.label-overflow-container {
        top: 0;
        display: flex;
        width: max-content;
      }
    }

    label.input-helper {
      position: relative;
      left: 0;
      display: flex;
      width: 100%;
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
      color: $tertiary-gray;
      font-family: $poppins-font;
      font-size: $font-size-sm;
      font-weight: 400;
      line-height: 2rem;
    }
  }

  .input-icon {
    position: absolute;
    top: .5rem;
    right: -.25rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    align-items: center;

    &.error-icon {
      color: $secondary-red;
    }

    &.password-icon {
      color: #f2f2f2;
      cursor: pointer;
    }
  }

  .right-side-icon {
    color: $secondary-red;
    font-size: 2.1rem;
  }
}
