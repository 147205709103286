.pagination {
  display: flex;
  width: fit-content;
  column-gap: 0.8rem;
  cursor: pointer;

  .page-nav {
    color: $tertiary-gray;

    &:disabled {
      color: rgba($tertiary-gray, 0.5);
    }
  }

  .page-item {
    width: 2.4rem;
    padding: 0.4rem 0;
    border-radius: 50%;
    color: $primary-green;
    font: 400 $font-size-md $poppins-font;
    line-height: 1.6rem;
    text-align: center;

    &.active {
      background-color: $primary-green;
      color: $secondary-white;
      font-weight: 600;
    }
  }
}
