nav {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  height: $navbar-height;
  align-items: center;
  padding: 1rem 3.2rem;
  background-color: $secondary-beige;

  a > img {
    width: 4.6rem;
    height: 4.7rem;
  }

  .nav-buttons {
    gap: 0.8rem;
  }

  .nav-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon-nav {
    background-color: $secondary-beige;
    color: $primary-green;

    &:hover,
    &:active,
    &:focus {
      background-color: $secondary-beige;
    }

    &.size-md {
      width: 4.8rem;
      height: 4.4rem;
    }
  }

  .delete-carapine-button {
    > span.icon {
      margin-left: 1.6rem;
    }

    width: 16rem;
    align-items: center;

    &.over {
      background-color: $quaternary-gray;
      box-shadow: unset;
    }

    &:hover,
    &:focus {
      background-color: $quaternary-gray;
      box-shadow: unset;
    }
  }
}
