main.authentication-page {
  .panel {
    gap: unset;

    h2 {
      margin-bottom: 2.4rem;
    }
  }

  a {
    color: $primary-green;
    line-height: $line-height-base;
    text-align: center;
  }

  .login-item {
    height: 12rem;
  }
}
