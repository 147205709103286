@use "sass:math";

/*
--------------------------------------------------------------------------------
Mobile first queries
--------------------------------------------------------------------------------
*/

@mixin phone {

  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin tablet {

  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin small-desktop {

  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin desktop {

  @include media-breakpoint-up(xl) {
    @content;
  }
}

@mixin large-desktop {

  @include media-breakpoint-up(xxl) {
    @content;
  }
}

@mixin resolution($r) {

  @media (min-width: #{$r}) {
    @content;
  }
}

/*
--------------------------------------------------------------------------------
Custom helpers
--------------------------------------------------------------------------------
*/

@mixin slide-up-down($name, $from, $to) {

  @keyframes #{$name} {
    0% {
      margin-top: $from;
    }

    100% {
      margin-top: $to;
    }
  }
}

@mixin translate-up-down($name, $from, $to) {

  @keyframes #{$name} {
    0% {
      transform: translateY($from);
    }

    100% {
      transform: translateY($to);
    }
  }
}

@mixin fade-from-above($name, $from, $to) {

  @keyframes #{$name} {
    0% {
      margin-top: -$from;
      margin-bottom: $from;
      opacity: 0;
    }

    100% {
      margin-top: $to;
      margin-bottom: -$to;
      opacity: 1;
    }
  }
}

@mixin fade-from-side($name, $from, $to, $side: left) {

  @keyframes #{$name} {
    0% {
      opacity: 0;
      margin-#{$side}: $from;

      @if $side == "left" {
        margin-right: -$from;
      } @else {
        margin-left: -$from;
      }
    }

    100% {
      opacity: 1;
      margin-#{$side}: -$to;

      @if $side == "left" {
        margin-right: $to;
      } @else {
        margin-left: $to;
      }
    }
  }
}

/*
--------------------------------------------------------------------------------
Font helpers
--------------------------------------------------------------------------------
*/

@function strip-unit($number) {

  @if type-of($number) == "number" and not unitless($number) {

    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

@mixin responsive-fonts($font, $min, $max, $weight: 300, $line-height: 1) {
  font-family: $font;
  font-size: #{$min};
  font-weight: $weight;
  line-height: $line-height;

  @include resolution(672px) {
    font-size: calc(#{$min} + (#{strip-unit($max - $min)} * 10) * ((100vw - 67.2rem) / 576));
  }

  @include resolution(1200px) {
    font-size: #{$max};
  }
}
