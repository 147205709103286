/*
--------------------------------------------------------------------------------
Theme Colors utilities
--------------------------------------------------------------------------------
*/

$colors: (
  "primary-yellow": $primary-yellow,
  "primary-green": $primary-green,
  "primary-blue": $primary-blue,
  "primary-purple": $primary-purple,
  "secondary-beige": $secondary-beige,
  "secondary-black": $secondary-black,
  "secondary-white": $secondary-white,
  "secondary-red": $secondary-red,
  "tertiary-gray": $tertiary-gray,
);

:root {

  @each $colorkey, $color in $colors {
    --#{$colorkey}: #{$color};
  }
}

html, body {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

button {
  touch-action: none;
}

body {
  $rounded: 5, 8, 15;

  @each $round-val in $rounded {
    .rounded-#{$round-val} {
      border-radius: #{$round-val * .1}rem !important;
    }
  }

  @each $colorkey, $color in $colors {
    .color-#{$colorkey} {
      color: $color;
    }
  }

  @each $colorkey, $color in $colors {
    .bg-#{$colorkey} {
      background-color: $color;
    }
  }

  /*
  --------------------------------------------------------------------------------
  Theme fonts utilities
  --------------------------------------------------------------------------------
  */

  $font-families: (
    poppins: $poppins-font,
  );
  $font-sizes: 1, 1.1, 1.2, 1.4, 1.6, 1.8, 2.2, 2.4, 3.2, 3.6, 4.8, 6.4;
  $font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

  @each $font-size in $font-sizes {
    .f-#{calc($font-size * 10)} {
      font-size: #{$font-size}rem;
    }
  }

  @each $font-tag, $font-declaration in $font-families {

    @each $weight in $font-weights {
      .#{$font-tag}-#{$weight} {
        font-display: swap;
        font-family: $font-declaration;
        font-weight: #{$weight};
      }
    }
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;

    @for $i from 1 through 10 {
      &.max-line-#{$i} {
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: #{$i};
      }
    }
  }

  main {
    position: relative;
    display: flex;
    height: calc(100vh - $navbar-height);
    flex-direction: column;
    justify-content: flex-start;
    margin-top: $navbar-height;
    background-color: $primary-green;
    overflow-y: auto;
    place-items: center;
  }

  a {
    color: $primary-green;
    cursor: pointer;
    line-height: $line-height-base;
    text-decoration: none;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  .prevent-select {
    user-select: none;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    img {
      user-select: none;
    }
  }

  .panel {
    display: flex;
    min-width: 44rem;
    flex-direction: column;
    padding: 3.2rem;
    border-radius: $border-radius-15;
    margin-top: 7.7rem;
    background-color: $secondary-beige;
    box-shadow: 0 0 2.4rem 0 #526c57;
    gap: 4.8rem;
  }
}
