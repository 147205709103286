/*
--------------------------------------------------------------------------------
Configuration sizes for margins & paddings
--------------------------------------------------------------------------------
*/

$spacing-10: 8rem;
$spacing-9: 7.2rem;
$spacing-8: 6.4rem;
$spacing-7: 5.6rem;
$spacing-6: 4.8rem;
$spacing-5: 4rem;
$spacing-4: 3.2rem;
$spacing-3: 2.4rem;
$spacing-2: 1.6rem;
$spacing-1: .8rem;
$navbar-height: 6.4rem;

/*
--------------------------------------------------------------------------------
Borders
--------------------------------------------------------------------------------
*/

$border-radius-5: .5rem;
$border-radius-8: .8rem;
$border-radius-10: 1rem;
$border-radius-15: 1.5rem;
$border-radius-20: 2rem;

/*
--------------------------------------------------------------------------------
Doppo Color Paletts
--------------------------------------------------------------------------------
*/

$primary-yellow: #c9ae74;
$primary-green: #728c77;
$primary-blue: #426383;
$hover-blue: #1d4164;
$primary-purple: #9f8db3;
$secondary-beige: #dfdad9;
$secondary-black: #000;
$secondary-white: #fff;
$secondary-red: #d24a4a;
$hover-red: #b03434;
$secondary-red-10: #d24a4a1a;
$tertiary-gray: #828282;
$quaternary-gray: #BFBFBF;

/*
--------------------------------------------------------------------------------
Fonts
--------------------------------------------------------------------------------
*/

$poppins-font: "Poppins", sans-serif;
$material-icons-font-size: 1.6rem !default;
$font-size-xxxl: 3.2rem;
$font-size-xxl: 2.4rem;
$font-size-xl: 2rem;
$font-size-lg: 1.8rem;
$font-size-md: 1.4rem;
$font-size-sm: 1.2rem;
$font-size-xs: 1.1rem;
$line-height-base: 2.4rem;
$line-height-md: 2.8rem;
$line-height-sm: 1.25rem;
$line-height-lg: 2rem;
